







import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'Page404'
})
export default class extends Vue {
  private message = '404'
}
